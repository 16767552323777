require('./bootstrap');

/**
 * Le formulaire est envoyé seulement si confirmation par boîte de dialogue
 */

$('.confirm_form').submit(function(event){
   event.preventDefault();
});

$('.confirm_modal').on('shown.bs.modal', function() {
    let form = $(this).attr('data-form');
    $('#confirm_annuler_'+ form).trigger('focus');
    $('#confirm_confirm_'+ form).click(function(){
        $("#"+form).unbind('submit').submit();
        $('#confirm_'+form).modal('toggle');
    });
});

/**
 * Désactiver/activer le bouton d'envoi selon l'état de la case à cocher
 */
$(".required-checkbox").change(function () {
    // Desactiver devient vrai si la case n'est pas cochée
    const desactiver = !$(this).prop('checked');
    $('.required-checkbox-button').prop('disabled', desactiver);
});

/**
 * Afficher le nombre de caractères restants selon la propriété maxLength
 */
$('.maxlength').on('keyup', function () {
    let count = $(this).attr('maxLength') - $(this).val().length;
    $("#nb-"+$(this).attr('id')).text(count);
});
